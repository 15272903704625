import React from 'react';
import Header from '../components/Header';
import notfound from '../images/not-found.jpg';
import { Link } from 'gatsby';
import SEO from '../components/SEO';

const PageNotFound = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <SEO title="Page Not Found" />

      <Header className="flex-shrink-0 flex-grow-0" />
      <div className="container flex-auto flex items-center justify-center">
        <div
          className="space-y-4 md:p-32 lg:p-52"
          style={{
            background: `url(${notfound})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}
        >
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-center font-bold m-auto">
            Page Not Found
          </h1>
          <p className="text-xl text-blue text-center m-auto">
            Sorry, the requested URL could not be found.
          </p>
          <div className="flex justify-center pt-8">
            <Link
              to="/"
              className="
                bg-primary text-white text-xl font-bold py-4 px-6
                border border-primary rounded-sm focus-ring transition hover:bg-dark-primary
              "
            >
              Back to Home Page
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
